@import "../../sass/module";

@mixin form-buttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:10px;
}
.form-buttons{
  @include form-buttons;
}

.footer-buttons {
  @include form-buttons;
  margin-top: 15px;
  // only when the screen is long enough
  @media(min-height:600px){
    margin-top: 20px;
  }
}


.modal-tab-content {
  display:flex;
  flex-direction: column;
  gap: 3px;
  overflow: auto;
  max-height: 60vh;
  //padding: 0 15px 10px;
  padding-right:10px;
  // only when the screen is long enough
  @media(min-height:600px){
    gap: 15px;
  }

  &[data-selected="false"] {
    display: none;
  }

  &.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
    border-bottom: 1px solid $gray-background-color;
  }


  .assign-card-section {
    margin-top: 5px;
    .title {
      font-size: $font-size-md;
      color:$color-grey-dark;
      font-weight:$font-weight-bold;
    }
    .description {
      font-size: $font-size-xs;
      color: $breadcrumb-inactive-color;
      margin-bottom: 10px;
    }
  }
}


.modal-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  .modal-tab {
    display: flex;
    justify-content: flex-start;
    border: none;
    border-top: 4px solid $gray-background-color;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 10px 10px 10px 0;
    width:100%;
    background: none;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;

    &:hover, &[data-selected="true"] {
      border-top: 4px solid $secondary-color;
      color: $secondary-color;
    }

    &:disabled {
      cursor: not-allowed;
      border-top-color: $color-grey-very-light;
      color: $icon-light-grey;
    }
  }
}


.fuel-card-combobox-menu {
  padding: 0 5px;
  font-size: $font-size-sm;
  word-break: break-word;
  line-break: normal;
  border-left: 2px solid $gray-background-color;

  p {
    color: $text-color;
    margin-bottom: 1px;
    width: fit-content;
  }
  .vehicle-name {
    font-weight: $font-weight-semi-bold;
    width:100%;
    word-break: break-word;
  }

  label {
    font-weight: $font-weight-semi-bold;
    width: fit-content;
    font-size: $font-size-xs;
    color:$icon-grey-color;
  }


}