@import "../../../sass/module";

.customer-fields-content {
  width:50%;
  flex-grow:1;
  min-width: 500px;

  .card-content {
    width:100%;
    overflow-x:auto;
    .header{
      display:flex;
      width:100%;
      justify-content:space-between;
      font-size:18px;
      margin-bottom:1em;
      .button-group {
        display:flex;
        gap:10px;
      }
    }
    .bottom-part {
      display:flex;
      width:100%;
    }
  }

}
.fields-container {
  display: flex;
  flex-wrap: wrap;
  row-gap:15px;
  justify-content:space-between;
  width:100%;
  .field-container {
    padding-right:1em;
    width:calc(50% - $input-default-padding-x);

    .field-title {
      color:$icon-grey-color;
      font-size:14px;
      font-weight:500;
    }
    .field-content {
      font-size:16px;
      color:$color-grey-dark;
      font-weight:400;
      word-break: break-all;
    }
  }
}


.no-fields-container {
  display:flex;
  white-space: nowrap;
  justify-content: center;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  align-items: center;
  flex-direction: column;
  font-size: 18px;
}