@import "../../sass/module";
.driver-form{
  width: 100%;
  min-width: fit-content;
}
.driver-form-inputs-container {
  background: white;
  border-radius:12px;
  border: 1px solid $grid-separator-color;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  padding:1em 2em 2em 2em;
  .header {
    display: flex;
    align-items: center;
    margin-bottom:1em;
    justify-content: space-between;
    flex-wrap: wrap;
    .header-text {
      display:flex;
      flex-direction:row;
      gap:15px;
    }
    .header-buttons {
      display:flex;
      flex-direction:row;
      gap:10px;
      justify-content:flex-end;
      .edit-icon {
        fill:white;
        margin-top:1px;
      }
      .save-icon {
        margin-top:1px;
      }
      .cancel-icon {
        stroke:$icon-grey-color;
        margin-top:2px;
      }
    }
    .question-mark-icon {
      cursor:pointer;
      stroke:$tab-color;
    }
  }
  .body {
    display: grid;
    flex-direction: column;
    row-gap: 10px;
    column-gap: 20px;
    //grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  @media(min-width: 900px){
    .body {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media(max-width: 900px){
    .body {
      grid-template-columns: repeat(auto-fit, 1fr)
    }
  }

}
.form-item-container {
  .form-item-title {
    font-size:$font-size-sm;
    color: $icon-grey-color;
    font-weight:$font-weight-semi-bold;
  }
  .form-item-content {
    max-width:fit-content;
    margin-top:5px;
  }
  .form-item-text {
    // the padding is added so that the row height is relatively stable when user click on the edit button. Otherwise the input field is much more taller than the text. 
    padding:7.5px 0px;
    font-size:$font-size-md;
    color: $color-grey-dark;
    font-weight:400;
    // add a max-width to force the text to wrap if the text is too long
    max-width: 300px;
    // center the text vertically in the span if a neighbor field is wrapped into 2 lines
    display: inline-flex;
    align-items: center;
  }
}

.info-container {
  align-items:flex-start;
  display:flex;
  flex-direction:column;
  margin-top:1em;
}

.city-zip-container {
  display: flex;
  column-gap:10px;
  width: fit-content;
  flex-direction: row;
}

.address-inputs-container{
  display: flex;
  column-gap: 5px;
  align-items: flex-start;
}


.icon{
  height: 35px;
  width: 35px;
  cursor: pointer;
  padding: 5px;
  border-radius: $border-radius-sm;
  display: flex;
  &:hover {
    fill:$brand-color;
    @media (hover: none) {
      fill:$brand-color;
    }
  }
}
.title-button-container{
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;

  .button-group{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
  }
}
.loading-spinner-container{
  position: relative;
  width: 100%;
  // why is here a height
  height:400px;
}

.password-form-group{
  label{
    font-weight:bold
  }
}

