@import "../../sass/module";

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
  margin-bottom: 10px;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;

  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  border-radius: $border-radius-sm;
}

.dropdown .options.open {
  display: block;
}
// mixin is used for displaying individual option/choice and also warning/hint for users
@mixin option{
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  display: block;
  padding: 6px 10px;
}
.dropdown .option {
  @include option;
  cursor: pointer;
  // add a bit of margin between the options. (except for the last child)
  &:not(:last-child){
    margin-bottom: 2px;
  }
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: $brand-color-lighter;
  color: #333;
}

.search-warning{
  @include option;
}