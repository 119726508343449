@import "../../sass/module";

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab {
  &:hover {
    color: $icon-grey-color;
  }
  padding:8px 12px;
  border-radius: $border-radius-xl;
  background:transparent;
  color: $tab-color;
  cursor:pointer;
  font-weight: 700;
  font-size:$font-size-md;
  &.active {
    background: $secondary-bg-color;
    color: $icon-grey-color;
  }
}
.tab-group {
  white-space:nowrap;
  display:flex;
  gap:8px;
  align-items:center;
  margin-bottom:30px;
  flex-wrap: wrap;
}