@import "../../sass/_variables.scss";

.ag-theme-alpine {
  overflow: hidden;

  --ag-borders: none;
  --ag-background-color: #{$secondary-bg-color};
  --ag-header-background-color:white;
  --ag-header-column-resize-handle-color:white;
  --ag-row-border-color:#eaecf0
}
.ag-theme-alpine .ag-header {
  border-bottom: solid #eaecf0 1px;
  border-top:  solid #eaecf0 1px;
}
