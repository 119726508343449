@import "../../sass/module";
@import "../CarInfoBanner/style";


.name-status-stoplights{
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-items: flex-start;
  h2{
    margin: 5px 0;
  }
}

.icon-info-container{
  display: flex;
  column-gap: 10px;
  align-items: center;
  svg{
    height:20px;
    fill:white;
  }
  #email-address{
    color: white;
  }
}