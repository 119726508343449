
@import "../variables";

.number-cards-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0px;
  column-gap: 15px;
  row-gap: 15px;
  // the cards are defined as sass components
}


.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  justify-content: space-around;
}

@mixin card($background-color, $icon-color, $min-height: 160px, $min-width:210px){

  width: 23.5%;
  min-width: $min-width;
  height: fit-content;
  min-height: $min-height;
  border: 1px solid $grid-separator-color;
  border-radius: $border-radius-lg;
  padding: 18px;
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  box-shadow:0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

  .card-interactive-icon{
    position:absolute;
    top: 15px;
    right: 15px;
  }
  .card-title{
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-sm;
  }
  .card-number{
    font-weight: 600;
    font-size: 1.9em;
  }
  .icon-wrapper{
    height: 48px;
    width: 48px;
    border-radius: $border-radius-sm;
    background-color: $background-color;
    display: flex;
    align-items: center;
    justify-content: center;
    .card-icon{
      stroke:$icon-color;
    }
  }
}
.grid-icon {
  stroke: $tab-color;
  &:hover{
    stroke: $icon-grey-color;
  }
}
.card-positive{
  @include card($brand-color-lighter, $brand-color);
}
.card-neutral{
  @include card($secondary-color-lighter, $secondary-color);
}
.card-orange{
  @include card(#FFFAEB, #FDB022);
}
.card-grey{
  @include card($disabled-input-color, $icon-grey-color);
}
.card-neutral-light{
  @include card($secondary-color-lighter, white)
}
.card-grey{
  @include card($grid-separator-color, $icon-light-grey)
}
.card-negative{
  @include card($card-negative-color-lighter, $card-negative-color)
}
