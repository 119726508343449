@import "../../sass/module";
@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';


@mixin stylingForStartAndEndDate($border-radius, $background-color-bottom:$secondary-bg-color-heavier, $background-color-circle:$breadcrumb-active-color1){
  position: relative;
  background-color: $background-color-bottom;
  border-radius: $border-radius;
  z-index: 0;

  &:before{
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $background-color-circle;
    border-radius: $border-radius-xl;
    z-index:-1;
  }
  &:hover:before {
    background-color: darken($background-color-circle, 5%);
  }
}

@mixin stylingDaysInRangeOrInSelection($background-color:$secondary-bg-color-heavier, $border-radius-bottom:0px){
  // except for the range-start and range-end
  color: $icon-grey-color;
  background-color: $background-color;
  position: relative;
  border-radius: $border-radius-bottom;
  &:before{
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: $border-radius-xl;
  }
  &:hover:before {
    background-color:rgba(0, 0, 0, 0.11);
  }
}

.react-datepicker__tab-loop {
  position:absolute;
  top:0;
  left:0;
  z-index: $z-index-modal;
}


.date-picker-popper {
}

.react-datepicker-wrapper {
  height:45px;
  width:100%;
  min-width:30px;

  .react-datepicker__input-container{
    height:100%;
    min-width:100%;
    display:flex;
    align-items:center;
    justify-content:center;

    .date-input-field {
      display: flex;
      padding: 10px 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: $border-radius-xl;
      border:solid 1px $breadcrumb-active-color1;
      background-color:white;
      width:100%;
      height:40px;
      overflow:hidden;
      font-weight:$font-weight-semi-bold;
      font-size:$font-size-md;
      color:$icon-grey-color;
      stroke:$icon-grey-color;
      &:hover {
        border:solid 1px $brand-color;
      }
      &:disabled {
        color:$icon-light-grey;
        border:solid 1.5px $icon-light-grey;
        background: none;
        &:hover {
          border:solid 1.5px $icon-light-grey;
        }
        .calendar-icon {
          stroke: $icon-light-grey;
        }
      }
      &.form {
        display: flex;
        flex-direction: row;
        border:solid 1px $color-grey-light;
        height: 39px;
        margin-bottom: 6px; // Required because date picker has additional container so not aligned with other inputs
        &:hover {
          border:solid 1px $breadcrumb-active-color1;
        }
        &[data-invalid="true"] {
          border: 1px solid red;
          color: red;
          stroke: red;
        }
        &:disabled {
          color:$icon-light-grey;
          border:solid 1px $color-grey-light;
          background: none;
          cursor: not-allowed;
          &:hover {
            border:solid 1px $color-grey-light;
          }
          .calendar-icon {
            stroke: $icon-light-grey;
          }
        }
      }
    }
  }
}




.react-datepicker {
  &.month-year-container{
    color:$icon-grey-color;
    padding:0px 15px 15px 15px;
    border-color: $color-grey-very-light !important;
    border-radius: 10px;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

    .react-datepicker__month-container {

      .react-datepicker__month-wrapper {
        justify-content:center;
        align-items: center;
        display:flex;
        gap:45px;

        .react-datepicker__month-text, .react-datepicker__month-text today, .react-datepicker__month-text--keyboard-selected {
          background: none;
          display:flex;
          font-size:$font-size-xs;
          font-weight:400;
          height: 35px;
          width: 35px;
          border-radius:20px;
          justify-content: center;
          align-items: center;
          text-align:center;
          text-transform: capitalize;


          &:hover {
            background-color: $color-grey-very-light;
          }

          &--selected {
            color: white;
            background-color: $breadcrumb-active-color1;
            &:hover {
              background-color: $button-hover-color ;
            }
          }

        }
      }



    }
  }

  &.date-picker-container{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height: 425px;
    border:1px solid $color-grey-very-light !important;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  }
  .react-datepicker__children-container {
    width:275px;
    margin:0 auto;
    padding: 0 5px;
  }
}

//Days Container
.react-datepicker__month-container {
  font-size: $font-size-sm;
  font-family: $font-primary;
  //border-bottom: 1px solid $grid-separator-color;
  display:flex;
  align-items:center;
  flex-direction:column;
  justify-content: center;

  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__month {
    width:300px;
    margin: 0;
    //Individual Day Items
    .react-datepicker__day {
      color: $icon-grey-color;
      height: 34px;
      width: 34px;
      margin: 0.2rem 0;
      line-height: 32px;
      border-radius: $border-radius-xl;
      font-weight: $font-weight-semi-bold;

      &--keyboard-selected {
        background-color: transparent;
      }

      //During Selection
      &--in-selecting-range:not(&--selected):not(&--selecting-range-end):not(:first-child):not(:last-child){
        @include stylingDaysInRangeOrInSelection($secondary-bg-color-heavier);
      }
      // this is because at the start of the week, there is a border radius
      &--in-selecting-range:not(&--selected):not(&--selecting-range-end):first-child{
        @include stylingDaysInRangeOrInSelection($secondary-bg-color-heavier, $border-radius-xl 0 0 $border-radius-xl);
      }
      // this is because at the end of the week, there is a border radius
      &--in-selecting-range:not(&--selected):not(&--selecting-range-end):last-child{
        @include stylingDaysInRangeOrInSelection($secondary-bg-color-heavier, 0 $border-radius-xl $border-radius-xl 0);
      }

      &--selecting-range-start.react-datepicker__day--selected{
        @include stylingForStartAndEndDate($border-radius-xl 0 0 $border-radius-xl);
      }
      &--selecting-range-end{
        color:white;
        @include stylingForStartAndEndDate(0 $border-radius-xl $border-radius-xl 0, $secondary-bg-color-heavier, $breadcrumb-active-color1-lighter);
      }


      //After selection
      &--in-range {
        color: $icon-grey-color;
      }

      &--in-range:not(&--range-end):not(&--range-start):not(:first-child):not(:last-child) {
        @include stylingDaysInRangeOrInSelection();
      }
      &--in-range:not(&--range-end):not(&--range-start):first-child{
        @include stylingDaysInRangeOrInSelection($secondary-bg-color-heavier, $border-radius-xl 0 0 $border-radius-xl);
      }

      &--in-range:not(&--range-end):not(&--range-start):last-child{
        @include stylingDaysInRangeOrInSelection($secondary-bg-color-heavier, 0 $border-radius-xl $border-radius-xl 0);
      }

      &--range-end, &--selected {
        font-family: $font-primary;
        background-color: $breadcrumb-active-color1;
        color: $color-white;
        border-radius: $border-radius-xl;

      }
      &--range-start:not(&--range-end){
        @include stylingForStartAndEndDate($border-radius-xl 0 0 $border-radius-xl);
      }
      &--range-end:not(&--range-start){
        @include stylingForStartAndEndDate(0 $border-radius-xl $border-radius-xl 0);
      }
    }
  }



  .react-datepicker__day-names{
    font-size: $font-size-xs;
    font-weight: $font-weight-semi-bold;
    display:flex;
    justify-content:space-between;
    width:235px;
  }

  .react-datepicker__header {
    background:white;
    border:none;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
  }
}
#date-picker-cancel-button, #date-picker-confirm-button{
  font-size:$font-size-xs;
  height: 36px;
}
//Contains the date picker header
.date-picker-header {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items: center;
  padding: 2px 0;


  //Contains upper part of header ( " < June 2023 > " )
  .month-selection{
    font-family: $font-primary;
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-direction:row;
    font-size: $font-size-sm;
    font-weight:$font-weight-bold;
    white-space:nowrap;
    width:275px;

    //The month and year in header ("June 2023")
    .month-year-input-field {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .chevron-bottom-icon{
        stroke:$tab-color;
        &.flipped {
          transform: rotate(180deg);
        }
      }
    }


    //Styling for the "<" and ">" buttons in header
    .date-picker-header-button {
      border:none;
      background:transparent;
    }
  }


  //Contains Input fields in header
  .date-selection {
    display:flex;
    justify-content:space-around;
    align-items:center;
    flex-direction:row;
    width:100%;
    gap:2px;
    font-size: $font-size-md;

    //Input fields in header
    .header-date-input-field {
      color:$color-grey-dark;
      font-size: $font-size-sm;
      height: 36px;
      gap: 8px;
      align-self:stretch;
      border-radius:25px;
      border:1px solid $color-grey-light;
      max-width: 145px;
      text-align:center;
      cursor: text;
      &:focus {
        outline:none;
        border:1px solid $color-grey-dark;
      }
    }

    .header-date-input-field[type="date"]::-webkit-inner-spin-button,
    .header-date-input-field[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    //Connector "-" between the two input fields (only visible if isRange is true)
    .header-date-input-connector {
      display:flex;
      justify-content:center;
      font-size: $font-size-xl;
      color: $tab-color;
    }
  }

}

.date-picker-footer {
  border-top: 1px solid $color-grey-light;
  height:fit-content;
  display:flex;
  .date-picker-footer-content{
    margin-bottom: 10px;
    padding-top: 10px;
    width:100%;
    display:flex;
    justify-content:space-between;
    flex-direction:row;
    column-gap:10px;
  }
}

