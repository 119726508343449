@import "../../sass/module";

.cost-info-card-header{
  display:flex;
  justify-content: space-around;
  align-items:center;
  line-height: 1.2em;
  gap:10px;
  margin-bottom: 1.2em;
  .title {
    font-size: $font-size-md;
    font-weight: $font-weight-semi-bold;
  }
}
.cost-info-card-content {
  display: flex;
  font-size: 26px;
  font-weight: $font-weight-semi-bold;
}

.tooltip-icon {
  stroke: $tab-color;
  cursor:pointer;
}
