@import "../../../sass/module";

// Yue: this wrapper below is added so that when errors are shown, users can still scroll and see the buttons
// somehow if I directly add the properties to add-relation-form, there are a lot of scroll bars.
.add-relation-form-wrapper{
  max-height: 68vh;
  overflow: auto;
}
.add-relation-form {
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 1em;
  .form-group {
    width: 100%;
  }
}

.add-relation-icon {
  width: 18px;
  height: 18px;
  stroke: $icon-grey-color;

  &.link {
    stroke: black;
  }

  &.unlink {
    stroke: $icon-grey-color;
  }
}

.add-relation-note-section {
  display: flex;
  font-size: $font-size-sm;
  color: $page-name-font-color;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
  padding: 1em 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

}