.car-all-fields-form{
  width:100%;
  display: flex;
  flex-direction: column;
}
.form-sections{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form-buttons-wrapper{
  display: flex;
  justify-content: flex-end;
  min-width: 220px;
}
.data-section-wrapper{
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 950px){
    grid-template-columns: 1fr 1fr;
    gap:10px 25px;
  }
  gap:10px 25px;
  flex-wrap: wrap;
  .data-field{
    display: grid;
    grid-template-columns: 1fr 1fr;
    .form-item-title{
      flex-basis:48%;
      // force long titles like Batteriekapazität netto (kWh) to wrap
      max-width: 200px;
      // center the text vertically in the span
      display: inline-flex;
      align-items: center;
    }
  }
}