@import "../../../sass/module";

.primary-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom:30px;
  width:100%;

  .card-wrapper {
    display:flex;
    flex-direction: column;
    gap:15px;
    flex-grow:1;
  }
}



.financing-card {
  display:grid;
  border-radius: $border-radius-lg;
  background: white;
  border: 1px solid $grid-separator-color;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  width: 100%;
  .financing-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    border-bottom: 1px $grid-separator-color solid;
    padding-bottom: 10px;
    column-gap:20px;

    .key {
      color:$color-grey-dark;
      font-weight:$font-weight-semi-bold;
      word-break: break-all;
    }
    .value {
      word-break: break-all;
      text-align:right;

    }
  }
  .document-download-item{
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items:center;
    height:40px;
    color: $icon-grey-color;
    font-weight:$font-weight-semi-bold;
    font-size:$font-size-md;
    &:hover {
      background-color:$secondary-bg-color;
    }

    &:nth-child(even) {
      background-color: $grid-separator-color;
      &:hover {
        background-color:$color-grey-light;
      }
    }
  }
}