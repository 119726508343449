@import "../../../sass/_variables.scss";
.custom-tooltip-header{
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 800;
}

.custom-tooltip{
  font-family: $font-primary;
  font-size: 14px;

  .custom-tooltip-item {
    color: $tab-color;

    &.highlight{
      color: $icon-grey-color;
      font-weight:800;
    }

  }
}

.ag-chart-tooltip-content{
  background-color:white;
  border:none;
  border-radius: 5px;
}

.ag-chart-tooltip::after {
  border-top-color: white;

}

.type-tooltip{
  font-family: $font-primary;
  font-size:14px;

  .type-tooltip-title{
    color: $icon-grey-color;
    font-weight: $font-weight-bold;
    .marker{
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .type-tooltip-item{
    color: $tab-color;
  }
}