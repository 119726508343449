@import "../../sass/module";

.tooltip-content {
  color:white;
  padding:8px 12px;
  background: $color-grey-dark;
  border-radius: $border-radius-sm;
  box-shadow:0px 1px 3px rgba(16, 24, 40, 0.1);
  z-index: 1000;
  visibility: hidden;
}

#tooltip[data-show] {
  visibility: visible ;
}

.tooltip-content[data-show] {
  visibility: visible;
  display:block;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;

}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: hidden;
  content: '';
  transform: rotate(45deg);
}


#tooltip[data-show] > #arrow::before {
  visibility: visible;

}


#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -3px;

}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -3px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -3px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -3px;
}
