@import "../../../sass/module";
.plate-number-grid-container {
  width:45%;
  flex-grow:1;
  min-width:400px;
}
.header {
  font-size:$font-size-lg;
  font-weight: $font-weight-bold;
  display:flex;
  justify-content:space-between;
  align-items:center;
  gap:1em;

}