@import "../../../sass/module";

.main-card {
  display:flex;
  flex-grow:1;
  max-width:100%;
  //flex-wrap: wrap;
}

.left-side-content {
  border: 1px solid $grid-separator-color;
  border-right: none;
  border-radius: 12px 0px 0px 12px;
  font-weight:$font-weight-semi-bold;
  box-shadow: 1px 0px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  width:fit-content;
  min-width:35%;
  padding: 2em;

  .car-data-text-container {
    display: block;
    justify-content:space-between;
    max-width: 300px;
    width:fit-content;
  }
  .car-data-title {
    font-size:$font-size-xl;
    color: $color-grey-dark;
  }
  .car-data-subtitle {
    color: $breadcrumb-inactive-color;
    width:fit-content;
  }
  .car-data-image {
    width: 100%;
    max-width:300px;
    max-height:300px;
  }
}

.right-side-content {
  background:$secondary-bg-color;
  border: 1px solid $grid-separator-color;
  border-radius: 0px 12px 12px 0px;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

  padding: 2em 1.5em 2em 1.5em;
  // the goal is to put the table in the center of the card
  display: flex;
  justify-content: center;
  // provide some space for the swiping arrows
  flex-grow: 1;

  .general-car-data-table{
    font-size:$font-size-lg;
    //white-space: nowrap;
    border-collapse: separate;
    width:100%;
    height:100%;
    border-spacing: 1em 0;
    .labels {
      color: $breadcrumb-inactive-color;
      font-weight: 300;
    }
    .data {
      color: $color-grey-dark;
      font-weight:$font-weight-semi-bold;
      line-break: auto;
    }
  }
}