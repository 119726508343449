@import "../../sass/module";

.back-to-parent-container{
  display: flex;
  align-items: center;

  column-gap: 20px;
  p{
    margin: 16px 0px;

  }
  >:hover {
    cursor: pointer;
    color: $brand-color;
  }
}