@import "../../sass/module";

.damage-overview-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.damage-overview-header{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.add-damage-modal-container{
     display:flex;
     flex-direction: column;
     gap:10px;
     height: min(72vh,800px);
     
     overflow: auto;
     .form-item{
       width:100%;
       .label{
         color:$breadcrumb-inactive-color;
         font-size:$font-size-sm;
         font-weight:$font-weight-semi-bold;
       }
     }
  .search-car-labels {
    font-size:$font-size-sm;
    font-weight:$font-weight-bold;
    color:$icon-grey-color;
    display:flex;
    flex-direction: row;
    margin:0;
    gap: 5px;
    .search-car-label {
      height:min-content;
      margin:0;
      &.highlighted {
        color:$secondary-color;
        cursor:pointer;
        &:hover {
          text-decoration: underline;
        }
      }
  }



  }

     .footer-buttons{
       display:flex;
       flex-direction: row;
       justify-content: center;
       gap:10px;
     }

     .single-line-container{
       display:flex;
       flex-direction:row;
       gap: 10px;
     }
   }