@import "../../sass/module";

.warning-container {
  background-color: $warning-color-bg;
  border: 1px solid $warning-color-border;
  border-radius: $border-radius-sm;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0.75em 1em;

  &.success {
    background-color: $success-color-bg;
    border: 1px solid $success-color-border;
  }


  .warning-title {
    font-weight:$font-weight-bold;
    font-size: $font-size-md;
    line-height: 24px;

  }
  .warning-body {
    font-weight:$font-weight-semi-bold;
    font-size: $font-size-sm;

  }

}