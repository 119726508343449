@import "../../sass/module";

.feature-icon{
  display:flex;
  width:48px;
  height:48px;
  padding:12px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $grid-separator-color;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &.circle{
    width:56px;
    height:56px;
  }
}

.feature-icon-circular-outer {
  padding: 12px;
  border-radius:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .feature-icon-circular-inner {
    background-color:yellow;
    padding: 12px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}