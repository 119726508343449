@import "../../../sass/module";

.grid-title-container {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  .grid-title {
    color: $color-grey-dark;
    font-size: $font-size-lg;
    font-weight:$font-weight-semi-bold;
  }
  .grid-subtitle {
    color: $breadcrumb-inactive-color;
    font-size:$font-size-sm;
    font-weight:400;
  }
}
