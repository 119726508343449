@import "../variables";

.line-1{
  height: 1px;
  margin-block:2px;
  background: $icon-light-grey;
  width:100%;
}

.type-tooltip {
  .type-tooltip-cost{
    color: $icon-grey-color;
  }
  .type-tooltip-item{
    color: $tab-color;
  }
}