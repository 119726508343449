@import "../../sass/module";

.portal-login {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .header {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        width: 90%;

        * {
            min-width: 15%;
        }
    }
    .login-content{
        width: 85%;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        column-gap: 50px;
        align-items: center;
        .welcome-image{

        }
    }
    .form-container{
        max-width: 500px;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        align-items: center;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: space-around;

        width: 90%;

        gap: 10px;

        input {
            padding: 14px 31px;
            border: 1px solid $input-border-color;
            border-radius: 30px;

            &:focus {
                outline-color: $brand-color;
            }
        }
        // the margin is not needed for the email code input fields
        input:not(.two-factor-inputs-input){
            margin-bottom: 20px;
        }

        label {
            margin-bottom: 5px;
        }

        .user-help {
            display: flex;
            flex-direction: row;
            justify-content: center;

            a {
                color: $secondary-color;
                text-decoration: none;

                &:active {
                    outline-color: $secondary-color;
                }
            }
        }
    }
}



