@import "sass/_variables.scss";

.App{
  background-color: $body-bg;
  font-family: $font-primary;
  color: $text-color;
  height:100vh;
  *{
    //border:1px solid red;
  }
}

.section-container-content {
  display: block;
}
//note that the nth of type is of type "div", just that the rules only
//apply to class "section-card"
// the purpose of the code below is that not all cars have driver data
// in the case some section is missing. The background color should be
// applied dynamically in the individual car data pages.


.section-card{
  width: 100%;
  flex-grow: 1;
  //border-box is needed when we have width 100% and padding 10px
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: $body-bg;
}

::selection{
  background: $secondary-color;
  color:white;
}
.content-side{
  // among the 2 children this one grow
  flex-grow: 1;

  height: calc(100vh - $footer-height);
  width: 100%;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
  // relative so that loading spinner can be relative to this div
  position: relative;
  display: flex;
  flex-direction: column;
}
.content-side-padding{
  box-sizing: border-box;
  // Instead of adding margin to the cards on the bottom row, so that they dont directly the footer. It is probably better to just
  // add a padding to the right side (content side).
  padding:0 20px 20px 20px;
}
h1{
  font-weight: 700;
  font-size: 2em;
  margin:$h1-header-margin-vertical 0;
}
.h1{
  font-weight: 700;
  font-size: 2em;
  margin:$h1-header-margin-vertical 0;
}
h2{
  font-size: 1.5em;
  margin:$h2-header-margin-vertical 0;
}
.h2{
  font-size: 1.5em;
  margin: $h2-header-margin-vertical 0px;
}
th{
  padding-right: 10px;
}





