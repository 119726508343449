@import "../../sass/module";

@mixin info-banner {
  display: flex;
  flex-direction: column;

  color: white;
  border-radius: $border-radius-lg;
  padding: 10px 20px 10px 20px;
  > h1{
    margin-top: 0px;
  }
}

.info-banner-yellow{
  @include info-banner;
  background: linear-gradient(110deg, $secondary-color 80%, $teaser-warning-border-color);
}

.info-banner-green{
  @include info-banner;
  background: linear-gradient(110deg, $secondary-color 80%, $brand-color);
}

.info-banner-other{
  @include info-banner;
  background: linear-gradient(110deg, $secondary-color 80%, $upto-styleguide-azalea);
}

.dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
}

.status-stoplights{
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.yellow{
  background-color:$teaser-warning-border-color ;
}

.green{
  background-color:$brand-color ;
}

.other{
  background-color:$upto-styleguide-azalea ;
}