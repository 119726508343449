@import "../../sass/module";
.driver-info-card-container{
  display: flex;
  column-gap: 15px;
  align-items: center;

}

.driver-avatar{
  height:45px;
  fill: $list-bullet-color;
}

.driver-info-container{
  display: flex;
  flex-direction: column;
}