@import "../../sass/module";


.modal-container {
  // min-width and min-height are needed for modals with date picker
  // Yue: I reduced the min-width from 600px to 500xp so that there is enough space for date picker with ipad Air view
  min-width: max(500px,40vw);
  position:fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);

  .modal-close-icon {
    height:18px;
    width:18px;
    cursor: pointer;
    stroke:$icon-grey-color;
    &:hover {
      stroke:$icon-light-grey;
    }
  }
  .modal-header {
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:10px;
    font-size:$font-size-xxl;
    color:$color-grey-dark;
    font-weight:$font-weight-bold;
  }

}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  z-index: 1000; /* Place the overlay below the modal content */
}
// overwrite the top defined in the class modal-container so that the datepicker has enough vertical space
#driver-modal{
  top:40%
}


.form-modal-content {

  z-index:1;
  display:flex;
  margin: 20px 0;
  flex-direction:column;
  row-gap:20px;

  .form-modal-input-container{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
    line-height: 1;
    .form-modal-input-title {
      font-weight: $font-weight-bold;
      margin-top:10px;
      font-size:$font-size-lg;
      color:$color-grey-dark;
      display:flex;
      flex-direction:column;
      .required-label{
        font-size:$font-size-sm;
        color: $tab-color;
      }
    }
    .form-modal-input-container-field {
      width:200px;
    }

    &.with-checkbox {
      width:65%;
      font-weight: 500;
      font-size:$font-size-md;
    }
  }
}

.form-modal-footer {
  display: flex;
  justify-content: space-between;
}