@import "../../sass/module";

.info-cards-container{
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  margin-bottom: 20px;
  row-gap: 20px;
}

.text-status-wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  font-size: $font-size-sm;
  font-weight: 500;
}