@import "../../sass/_variables.scss";

.bill-details-page-content{
  display:flex;
  flex-direction: column;
  min-width: fit-content;
  gap:20px;

  .main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .chart-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .header-buttons-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        white-space:nowrap;
      }

      .chart-card{
        font-weight: 500;

        .chart-wrapper {
          width:85%;
          height:450px;

        }
      }
    }

    .grid-download-container {
      display: flex;

      .grid-download-content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .header{
          display:flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 10px;
          white-space:nowrap;

          .title {
            font-size: $font-size-md;
            font-weight: $font-weight-bold;
            color: $color-grey-dark;
          }
        }
      }


    }

  }


  .bill-info-cards-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap:wrap;

    .bill-info-card {
      height: 85px;
      flex-basis: 300px;
      flex-shrink:2;
      flex-grow:1;
      display:flex;
      justify-content: center;
      flex-direction: column;

      .bill-info-card-item{

        .bill-info-card-item-title{
          color: $breadcrumb-inactive-color;
          font-size: $font-size-sm;
          font-weight:$font-weight-semi-bold;
        }
        .bill-info-card-item-value{
          color: $color-grey-dark;
          font-size: $font-size-xl;
          font-weight:$font-weight-bold;

        }
      }
    }
  }


}