@import "../../sass/module";

.bills-overview-content {

}

.payment-details-container{
  width: 23.5%;
  min-width: fit-content;
  min-height: 160px;
  .payment-details-row{
    display:flex;
    flex-direction: row;
    gap:20px;
    .payment-details-icon{
      stroke: $breadcrumb-active-color1;
      background: $icon-background-color;
      border-radius:16px;
      display: flex;
      width: 32px;
      height: 32px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      mix-blend-mode: multiply;
    }
    .payment-details-item{
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 10px;
      .payment-details-title{
        color:$breadcrumb-inactive-color;
        font-size:14px;
      }
      .payment-details-text{
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
.grid-icon {
  stroke: $tab-color;
  &:hover{
    stroke: $icon-grey-color;
  }
}

