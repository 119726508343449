@import "../../../sass/module";

.document-generation-content {
  display:flex;
  flex-direction: column;
  gap:10px;

  .button-container{
    width: 200px;
  }
}