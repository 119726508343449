@import "../../sass/module";
.section-card {
  margin-block-end: 90px !important;
}

.button-icon {
  top: 0;
  bottom: 0;
  margin: auto 0;
  left:10px;
  stroke: $icon-background-color;
  &:hover{
    stroke: $icon-background-color;
  }
}

.button-icon {
  top: 0;
  bottom: 0;
  margin: auto 0;
  left:10px;
  stroke: white;
  &:hover{
    stroke: white;
  }
}