@import "../../sass/module";


.custom-dropdown {
  position: relative;
  font-family: $font-primary;
  display: inline-block;
  min-width:200px;

  .chevron {
    stroke: #667085;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    pointer-events:none; // Prevents click on the svg
  }
}
.custom-dropdown select {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 100%;
  font-size: $font-size-sm;
  border-radius:25px;
  padding: 6px 2em 6px 14px;
  background-color: #fff;
  border: 1px solid var(--gray-300, $color-grey-light);
  color: $tab-color;
  cursor: pointer;
}

.custom-dropdown select:focus {
  box-shadow: 0px 0px 2px 3px #D0FFFF;
  border: 1px solid $breadcrumb-active-color2;
  color: $color-grey-dark;
  font-weight:$font-weight-semi-bold;
}

