@import "../../sass/module";
.fade-loader-enter {
  opacity: 0;
}

.fade-loader-enter-active {
  opacity: 1;

}

.fade-loader-exit {
  opacity: 1;
}

.fade-loader-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.fade-loader-exit-done {
  opacity: 0;
  transition: opacity 200ms;
}

.loading-component {
  height: 100%;
  width: 100%;
  z-index: $z-index-loader;
  background: $color-white;

  .loading-rotate {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $color-white;

    .rotate-circle {
      fill: $brand-color;
    }

    .rotator {
      transform-origin: center center;
      transform: rotate(0);
    }

    .rotate-form {
      fill: $secondary-color;
      animation: rotating 2.5s ease-in-out infinite;
    }

    .rotate-group {
      animation: rotating 5s linear infinite;
    }

    @keyframes rotating {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}

