@import "../../sass/module";

@mixin base-alert {
  padding: 20px;
  border-radius: 12px;
  margin-top:10px;
  margin-bottom:10px;
}


.basic-alert-success {
  @include base-alert;
  color: $body-color;
  border-color: $brand-color;
  background-color: $brand-color-light;
  strong {
    color: $brand-color;
  }
}

.read-more{
  display: block;
  border-radius: 15px;
  border: none !important;
  margin-top:10px;
}

.basic-alert-warning {
  @include base-alert;
  color: $teaser-warning-color;
  border-color: $teaser-warning-border-color;
  background-color: $teaser-warning-bg;
}

.basic-alert-hint {
  @include base-alert;
  background-color: $error-color-bg;
  color: $error-color;
  border-color: $error-color;
}

.basic-alert-info {
  @include base-alert;
  background-color: $info-background-color;
  color: $body-color;

  > strong {
    color: $secondary-color;
  }

  p {
    margin-bottom: 0;
  }
}

