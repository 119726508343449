@import "../../sass/module";
.tooltip-icon {
  stroke: $tab-color;
  cursor:pointer;
}
.supplier-invoices-content{
  display:flex;
  flex-direction:column;
  gap: 10px;
}
.grid-icon {
  stroke: $tab-color;
  &:hover{
    stroke: $icon-grey-color;
  }
}