@import "../../sass/module";

.two-factor-handler {
  max-width: 600px;
  .two-factor-handler-header{
    display:flex;
    justify-content:center;
  }

  .two-factor-handler-content{
    p {
      overflow:hidden;
      display:block;
      color: $breadcrumb-inactive-color;
      font-size: $font-size-md;

      .highlighted {
        color: $breadcrumb-active-color2;
        font-weight: $font-weight-semi-bold;
        width: fit-content;

        &.clickable {
          cursor: pointer;
        }
      }

      .timer {
        width: 50px;
        display: inline-block;
        color: $color-grey-light;
      }
    }
  }

}