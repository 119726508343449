@import "../../sass/module";
.form {
  display:flex;
  flex-direction:column;
  gap: 10px;
}
@media (min-height: 800px) {
  .form {
    gap: 18px;
  }
}

.form-group {
    display:flex;
    flex-direction: row;
    align-items:flex-start;
    gap: 10px;
}

.form-item {
  display:flex;
  flex-direction: column;
  width:100%;
  gap:5px;
  // vertically center the input/text
  justify-content: center;

  &.column {
    flex-direction: row-reverse;
    gap: 10px;
    justify-content: start;
    align-items: center;
  }
}


.form-message {
  font-size:$font-size-sm;
  color: red;
  height:min-content;
}

.form-label {
  height:min-content;
  font-size:$font-size-sm;
  font-weight:$font-weight-bold;
  color:$icon-grey-color;
  margin: 0;
  &.invalid {
    color:red;
  }
}

.form-description {
  font-size:$font-size-sm;
  color:$icon-grey-color;
  height:fit-content;
  //margin-bottom: -10px; // Otherwise there is big gap between description and error message, this doesn't impact the layout
}

.form-value {
  font-size:$font-size-md;
  color:$icon-grey-color;
}

.clear-file-button {
    display:flex;
    position:absolute;
    justify-content:center;
    align-items:center;
    width: 20px;
    height: 20px;
    stroke: $error-message-text;
    top: 7px;
    right: 7px;
    cursor: pointer;
    &:hover {
        stroke: $card-negative-color;
    }
}