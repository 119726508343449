@import "../../sass/module";

.btn-wrapper{
  position: relative;
  display: flex;
  align-items: center;
  button{
    padding:10px 16px 10px 40px;
    stroke: white;
  }
}


