@import "../../sass/module";



.info-table{
  min-width: 70%;
  .generic-info-table-data{
    display: flex;
    column-gap: 10px;
    input{
      width: fit-content;
    }
  }
}

