//install bootstrap is needed for the imports below to work
//usage: breakpoint "md" is from bootstrap
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/variables";

$namespace-global: 'upto';

$breakpoint-desktop: md;

$grid-breakpoints: (
        xxs: 0,
        xs: 375px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$height-breakpoints: (
        // basically if the screen height is smaller than hp laptop..
        s: 560px,
);


// font
$font-primary: 'Space Grotesk',sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-family-base: $font-primary;

// default font size and line height
$font-size-default-mobile: 19px;
$line-height-default-mobile: 30px;

$font-size-default-desktop: 20px;
$line-height-default-desktop: 30px;

// color
$brand-color: #31CCB0;
$brand-color-light:#9ae6d8;
$brand-color-lighter:#ccf0e9;
$secondary-color: #282F96;
$secondary-color-light: #eeeeff;
$secondary-color-lighter: #ededff;
$secondary-color-lighter2:#f1f6fa;
$headline-color: #515B69;
$grid-separator-color: #EAECF0;
$body-color: #747474;
$button-hover-color: rgba(0, 0, 143, 0.5);
$card-negative-color:#B42318;
$card-negative-color-lighter:#FEF3F2;
$error-color: #FF0000;
$error-color-bg: #FFF2F2;
$list-bullet-color: #CCCCCC;
$brand-color-hover: rgba(49, 204, 176, 0.6);
$color-white: #fff;
$color-grey-dark: #101828;
$color-grey-light: #d2d2d3;
$color-grey-light: #D0D5DD;
$color-grey-very-light: #F2F4F7;
$body-bg: $color-white;
$secondary-bg-color: #f3f4f6;
$secondary-bg-color-heavier: #eff5fc;
$icon-grey-color:#344054;
$icon-black-color:#101323;
$icon-light-grey: #98A2B3;
$tab-color: #667085;
$input-border-color: #DFDFDF;
$input-halo-color:#6BB5FF;
$breadcrumb-inactive-color: #475467;
$breadcrumb-active-color1: #00008F;
$breadcrumb-active-color1-lighter: #bad9f1;
$breadcrumb-active-color2: #43BFAB;
$icon-background-color: #EFEFF9;
$info-background-color: #ECF0F5;
$gray-background-color: #ECEFF3;
$disabled-input-color: #F1F3F7;
$notification-action-border: #D1D5E7;
$teaser-info-bg: #EAFAF7;
$teaser-warning-color: #61481B;
$teaser-warning-border-color: #FFCB72;
$teaser-warning-icon-color: #FFA200;
$teaser-warning-border: #FCD385;
$teaser-warning-bg: #ffecb5;
$text-color: #000000;
$warning-color-bg: #FFFAEB;
$warning-color-border: #FEDF89;
$success-color-bg: #E9F7F1;
$success-color-border: #9FD9B4;

// status/alert message colors
$success-message-text: #027A48;
$success-message-bg: #F6FEF9;
$success-message-border: #6CE9A6;
$warning-message-text: #B54708;
$warning-message-bg: #FFFCF5;
$warning-message-border: #FEC84B;
$error-message-text: #D92D20;
$error-message-bg: #FFFBFA;
$error-message-border: #F97066;


// colors defined in the upto style guide from Ana
$upto-styleguide-azalea: #E196AA;
$upto-styleguide-dune: #FCD385;
$upto-styleguide-aquaGreen: #9FD9B4;
$upto-styleguide-teal: #027180;
$upto-styleguide-acid: #F0FF93;
$upto-styleguide-igloo: #B5D0EE;

// navbar
$page-name-font-weight:500;
$page-name-font-color:#344054;
// input
$input-btn-focus-width: 0;
$input-btn-focus-color: none;
$input-border-radius: 0;
$input-box-shadow: unset;
$input-btn-focus-box-shadow: unset;

// motion
$motion-default-duration: 300ms !default;
$motion-default-easing: cubic-bezier(0.4, 0.0, 0.2, 1);
$motion-in-duration: 250ms !default;
$motion-in-easing: cubic-bezier(0.4, 0.0, 0.6, 1);
$motion-out-duration: 200ms !default;
$motion-out-easing: cubic-bezier(0.4, 0.0, 1, 1);

$alert-border-radius: 0;


// page margin
$page-margin-breakpoints: (
        xxs: 36px,
        xs: 50px,
        sm: 40px
);

// gutters
$grid-gutters-breakpoints: (
        xxs: 12px,
        sm: 20px,
);

$grid-gutter-width: map-get($grid-gutters-breakpoints, xs);


/* Misc */
$z-index-header: 50;
$z-index-filters: 63;
$z-index-portal-menu: 70;
$z-index-modal: 100;
$z-index-loader: 5;


//footer
$footer-height:35px;

// border radius
$border-radius-xxl:30px;
$border-radius-xl:22px;
$border-radius-lg:12px;
$border-radius-sm:6px;

// title
$h2-header-margin-vertical: 15px;
$h1-header-margin-vertical: 15px;

// font sizes
$font-size-3xl: 30px;
$font-size-xxl: 24px;
$font-size-xl: 20px;
$font-size-lg: 18px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;

// font weights
$font-weight-bold: 700;
$font-weight-semi-bold: 500;

// input field
$input-default-padding-x:14px;
$input-default-padding-y:10px;
