@import "../../sass/module";


.not-found-page-content{
  height: 100%;
  background-color: $upto-styleguide-acid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1{
    font-size: 60px;
    margin: 0px;
  }
  button{
    max-width: 200px;
  }
}

