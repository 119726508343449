@import "../../sass/module";
@import "../../sass/components/input_field";

$gap-s: 9px;
$gap-m:18px;
@mixin text-area-container {
  border-radius: 8px;
  // padding should match with the class input-field-primary-damage-details
  padding: 10px 12px;
  font-size: $font-size-sm;
  white-space: pre-wrap;
  // border width should also match the class input-field-primary-damage-details
  border: 1px solid var(--gray-300, $color-grey-light);

  &:focus {
    outline: none;
    border: 1px solid $breadcrumb-active-color2;
  }
}

@mixin label {
  color: $breadcrumb-inactive-color;
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;
}

.text-with-hover{
  &:hover {
   color:$brand-color-hover
  }
}

@mixin display-mode-font-style {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  color: $color-grey-dark;
}

// move this class item-group-column and item-group-container Out of damage-details-page-content because it is also
// now used on damage overview page
.item-group-column {
  display: flex;
  flex-direction: column;
  gap: $gap-s;
  height: fit-content;
}
@media (min-height: 800px) {
  .form {
    gap: $gap-m;
  }
}
.item-group-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $gap-s;
  width: 100%;
  max-width: 600px;
}
@media (min-height: 800px) {
  .form {
    gap: $gap-m;
  }
}

.damage-details-page-content {
  .label {
    @include label;
  }

  .header-button-wrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: $gap-m;

    .info-card {
      flex-basis: 150px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: fit-content;
      height: inherit;
      // max-width: 600px;
      gap: $gap-s;

      .title {
        color: $color-grey-dark;
        width: 100%;
        //border-bottom: 1px solid $color-grey-light;
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
      }

      .info-card-content {
        height: 100%;
        width: 100%;

        .info-item {
          white-space: nowrap;
          line-height: 20px;
          //height:fit-content;


          .value {
            color: $color-grey-dark;
            font-weight: $font-weight-bold;
            font-size: $font-size-xl;

          }
        }



        .item-group-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: $gap-s;
        }
        @media (min-height: 800px){
          .item-group-row{
            gap: $gap-m;
          }
        }

        .card-row-content {
          display: flex;
          flex-direction: row;
          column-gap: 30px;
          align-items: center;
          height: 100%;

          .vehicle-image {
            max-width: 200px;
            max-height: 120px;
            margin-top: 1em;
            object-fit: cover;
            border-radius: $border-radius-lg;

          }
          .car-content-right-side{
            display: flex;
            flex-direction: column;
            justify-content: space-between;

          }


        }


        .damages-card-content {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          min-width: 400px;
          gap: 10px;
          height: 100%;

          .repair-icon {
            stroke: $breadcrumb-active-color2;
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
          .contact-person-icon{
            stroke: $breadcrumb-active-color2;
            width: 40px;
            height: 40px;
            object-fit: cover;
          }


          .damage-description-container {
            @include text-area-container;
            width: 100%;
          }
        }
      }

    }

    .notes-card-container {
      display: flex;
      min-width: 300px;
      flex-grow: 1;
      flex-shrink: 1;
      //max-width will prevent the card from growing
      //max-width: 400px;
      gap: 20px;
      color: $color-grey-dark;
      font-weight: $font-weight-semi-bold;

      .notes-textarea {
        @include text-area-container;
        resize: none;

      }
    }

    .third-party-content {
      display: flex;
      flex-direction: row;
      gap: 30px;
      line-height: 40px;
      height: 100%;

      .third-party-labels {
        color: $breadcrumb-inactive-color;
        font-size: $font-size-sm;
        font-weight: $font-weight-semi-bold;
      }

      .third-party-values {
        @include display-mode-font-style;
        display: flex;
        flex-direction: column;

      }
    }


  }
  select[disabled] {
    @include display-mode-font-style;
    background-color: white;
    border: none;
    padding: 0px !important;
  }
}



