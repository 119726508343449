@import "../../sass/module";

.mileage-warning-modal {
  padding: 1em 1em;

  .warning-container{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
    gap:1em;
    .warning-container-title {
      font-size: $font-size-xl;
      font-weight: 600;
    }
    .warning-container-message {
      font-size: $font-size-lg;
      font-weight: 400;
    }
    .warning-container-confirmation{
      display:flex;
      flex-direction:row;
      justify-content: space-around;
      width:100%;
    }
  }
}

.mileage-data-table {
  width: 90%;
  tr {
    border-bottom: 1px solid $border-color;
    &:last-child {
      border-bottom: none;
    }
  }
  white-space:nowrap;
  text-align:right;


}
.mileage-data-column {
  padding: 0 15px 0 0;

  .delete-icon {
    width: 18px;
    stroke:$breadcrumb-inactive-color;
    &:hover {
      stroke: $error-message-text;
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
      stroke: $icon-light-grey;
    }
  }

  &.mileage-counter {
    width:30%;
  }

  &.date{

  }

  &.highlighted {
    font-weight: $font-weight-semi-bold;
    background: $secondary-bg-color;
  }

}


.mileage-counter-column {
  width:30%;
  padding: 0 15px 0 0;
}

.date-column {
  padding: 0 15px 0 0;
}

.input-group-container {
  display:flex;
  flex-direction: row;
  white-space:nowrap;
  margin-bottom:1em;

  .input-group-close-icon{
    margin:auto;
    stroke: $icon-grey-color;
  }

  .input-group-open {
    display: flex;
    flex-direction: row;
    gap: 5px;
    transition: color 0.2s ease-in-out;
  }

  .input-group-closed.show, .input-group-open.show {
    width: 100%;
    transition:  all 0.3s ease-in-out;
    overflow: hidden;
  }

  .input-group-closed.hide, .input-group-open.hide {
    width: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
  }

  .input-group-closed.hide {
    transition: all 0s ease-in-out;
  }
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}