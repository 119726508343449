@mixin global-namespace {
  @at-root .#{$namespace-global} {
    @content
  }
}

@mixin text-size-default() {
  font-size: $font-size-default-mobile;
  line-height: $line-height-default-mobile;

  @include media-breakpoint-up($breakpoint-desktop) {
    font-size: $font-size-default-desktop;
    line-height: $line-height-default-desktop;
  }
}

@mixin transition-default($properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property + ' ' + $motion-default-duration + ' ' + $motion-default-easing, comma);
  }

  transition: $transition;
}