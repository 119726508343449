@import "../../sass/module";


.metric-card {
  display: flex;
  flex-direction: column-reverse;
  border-radius: $border-radius-lg;
  background: white;
  border: 1px solid $grid-separator-color;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  white-space: nowrap;
  position: relative;
  &:hover  {
    &.card-with-hover{
      box-shadow: 0 0 5px $brand-color-light;
      cursor: pointer;
    }
  }
  .metric-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .metric-card-title {
      color: $breadcrumb-inactive-color;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-sm;

    }

  }

  .metric-card-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;

    .metric-card-icon {
      stroke: $icon-black-color;
      margin-bottom: 5px;
    }

    }
}