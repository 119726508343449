@import "../../../sass/module";

.combobox {
  position:relative;
}

.combobox-list-container {
  display: flex;
  flex-direction: row;
  max-height: 225px;
  overflow: auto;
  align-items: flex-start;
  gap:5px;
  scrollbar-width: thin;

  // Position
  z-index: 10000;
  position: absolute;

  // Styling
  background: white;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-sm;
  padding: 7px;

}

.combobox-list {
  // Structure
  display: flex;
  flex-direction: column;
  width: 100%;

  &[data-menu="true"] {
    width: 60%;
  }

}

.combobox-group {
  height: 100%;
  width:50%;
  line-break: anywhere;
  overflow: hidden;
}

.combobox-item {
  display: flex;
  font-weight: $font-weight-semi-bold;
  align-items: center;
  justify-content: space-between;
  color: $color-grey-dark;
  flex-direction: row;
  padding: 5px;
  width:100%;
  cursor: pointer;
  border-radius: $border-radius-sm;
    &:hover {
      background: $color-grey-very-light;
    }

  &[data-disabled="true"] {
    &:hover {
      background: white;
      cursor: not-allowed;
    }
  }
  &[data-selected="true"] {
    background: $color-grey-very-light;

    .selected-check {
      display: block;
      stroke: $secondary-color;
      width: 15px;
      height: 15px;

    }
  }
}

.combobox-input {
  width: 100%;
  position:relative;
}