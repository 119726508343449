@import "../variables";

@mixin blank-card {
  border: 1px solid $grid-separator-color;
  border-radius: $border-radius-lg;
  // make the horizontal padding bigger so that the X button position looks better
  padding: 18px 27px;
  position:relative;
  box-shadow:0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.blank-card{
  width:100%;
  height:100%;
  @include blank-card();
}

@mixin info-card($label-font-size: 14px, $text-font-size:20px, $label-text-color: $breadcrumb-inactive-color){
  @include blank-card();
  min-width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 10%;

  .card-label{
    font-weight: 400;
    font-size: $label-font-size;
    color: $label-text-color;
  }
  .card-text{
    font-weight: 500;
    font-size: $text-font-size;
    color:$color-grey-dark;
  }
}

.info-card{
  @include info-card();
}