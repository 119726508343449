@import "../../sass/module";

@mixin check-box($color) {
  -webkit-appearance: none;
  appearance:none;
  min-width: 1.6em;
  max-width: 1.6em;
  min-height: 1.6em;
  max-height: 1.6em;
  border-radius: 0.15em;
  border: 0.15em solid $color;
  outline: none;
  cursor: pointer;
  &:checked {
    background: $color;
    border-color: $color;
    &:after {
      content: '\2714';
      display: block;
      text-align: center;
      color: white;
      font-size: 1em;
      line-height: 1.5em;
    }
  }
}


@mixin text-area-container{
  border-radius: 8px;
  // padding should match with the class input-field-primary-damage-details
  padding:10px 12px;
  font-size: $font-size-sm;
  white-space: pre-wrap;
  // border width should also match the class input-field-primary-damage-details
  border: 1px solid var(--gray-300, $color-grey-light);

  &:focus {
    outline: none;
    border: 1px solid $breadcrumb-active-color2;
  }
}

//Hide number input arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

@mixin input-field($border-color, $halo-color, $padding:$input-default-padding-y $input-default-padding-x) {
  border-radius: $border-radius-xl;
  padding: $padding;
  border: 1px solid $border-color;

  &:disabled {
    border: 1px solid $color-grey-light;
    background-color: $secondary-bg-color;
  }

  &:focus {
    border: 1px solid $halo-color;
    //box-shadow: 0 0 0 0.125em $halo-color;
    outline: none;
  }

  &::placeholder {
    color: $tab-color;
  }
}
.input-field-wrapper{
  // purpose: add the same padding etc. so that the layout does not have a sudden shift when edit is enabled
  padding: $input-default-padding-y $input-default-padding-x $input-default-padding-y 0px;
}

.input-field-primary{
  @include input-field($breadcrumb-active-color1,$brand-color);
}
.input-field-primary-damage-details{
  @include input-field($color-grey-light,$brand-color, $padding:8px 10px);
  font-size: $font-size-sm;
}

.input-field-warning{
  @include input-field($upto-styleguide-azalea,$upto-styleguide-azalea, $padding:8px 10px);
  font-size: $font-size-sm;
}
.input-field-neutral{
  @include input-field($color-grey-light,$color-grey-light, $padding:8px 10px);
  font-size: $font-size-sm;
}
.input-field-valid{
  @include input-field($brand-color,$brand-color, $padding:8px 10px);
  font-size: $font-size-sm;
}

.input-field-secondary{
  @include input-field($secondary-color,$secondary-color-lighter);
}

.input-field-outline{
  @include input-field($color-grey-light,$secondary-color-light);
  padding: 5px 9px 5px 9px;
}

select {
  @extend input;
  padding:5px;
  border: 1px solid $breadcrumb-active-color1;
  border-radius: 12px;
  &:focus {
    border: 1px solid $breadcrumb-active-color2;
    //box-shadow: 0 0 0 0.125em $breadcrumb-active-color2;
    outline: none;
  }
}

.input-field-form {
    @include input-field($color-grey-light,$secondary-color, 8px 8px);
    width: 100%;
    font-size: $font-size-sm;

    &[data-invalid="true"] {
      border: 1px solid red;
      color: red;
    }
}

.input-checkbox-form {
  @include check-box($breadcrumb-active-color1);
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;

  &:checked {
    &:after {
      content: '\2714';
      display: block;
      text-align: center;
      color: white;
      font-size: 12px;
      line-height: 1em;
    }
  }
}

.select-form {
  @extend input;
  padding:8px;
  font-size: $font-size-sm;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-xl;
  text-overflow: ellipsis;
  white-space: nowrap;
  width:100%;
  &:focus {
    border: 1px solid $secondary-color;
    //box-shadow: 0 0 0 0.125em $breadcrumb-active-color2;
    outline: none;
  }

  &[data-invalid="true"] {
    border: 1px solid red;
    color: red;
  }
  &:disabled {
    background: $secondary-bg-color;
  }
}


.input-file-form {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $secondary-color;
  background-color: white;
  border-radius: $border-radius-lg;
  border: 1px solid $color-grey-light;
  padding: 10px;
  height: 100px;
  width: 100%;
  margin-left: 1px;
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;

  &:hover,&[data-fileDrag="true"] , &:focus {
    border: 1px solid $breadcrumb-active-color1;
  }

  &[data-invalid="true"] {
    border: 1px solid red;
    color: red;
  }

}

.text-area-primary{
  @include text-area-container;
  width:100%;
}

.password-form-input{
  width: 100%;
  @include input-field($breadcrumb-active-color1,$brand-color, 5px 14px);
}

.check-box-primary {
  @include check-box($breadcrumb-active-color1);
}