@import "../../sass/_variables.scss";

$margin: 25px;
span {
  font-family: $font-primary;
}

div.ag-cell {
  font-family: $font-primary;
  .icon{
    width: 25px;
    height: 25px;
    display: inline-block;
  }
  span.link-with-icon {
    position: relative;
    cursor: pointer;
    &::before {
      content: url("../../assets/svg/icon-magnifier-with-fill.svg");
      width: 15px;
      height: 15px;
      margin-right: 15px;
    }
    &:hover {
      filter: brightness(0) saturate(100%) invert(16%) sepia(65%) saturate(3138%) hue-rotate(230deg) brightness(83%) contrast(95%);
          }
  }
}
.ag-grid-overall-container{
  border: 1px solid $grid-separator-color;
  border-radius: $border-radius-lg;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  // button group does not fit in if the width is smaller than 300px;
  min-width:fit-content;
}

.grid-container {
  flex-grow: 1;
}

.ag-grid-flex-container {
  border-radius: $border-radius-lg;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.ag-grid-top-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top:10px;
  flex-wrap: wrap;
}

.ag-grid-title-container {
  display:flex;
  flex-direction: column;
  line-height: 20px;
  .ag-grid-title {
    color: $color-grey-dark;
    font-size: 18px;
    font-weight:500;
  }

  .ag-grid-subtitle {
    color: $breadcrumb-inactive-color;
    font-size:14px;
    font-weight:400;
  }
}

.ag-grid-download-button-container{
  display: flex;
  align-items: center;
  column-gap: 10px;
  .btn{
    font-size: 14px;
    font-weight: 700;
  }
}

.ag-grid-bottom-container {
  display:flex;
  justify-content: space-between;
  padding:1em;
}
.ag-grid-bottom-text{
  display:flex;
  align-items:center;
  font-weight: 500;
  color: $icon-grey-color;
  margin-left:1em;
  font-size: 14px;
}
.ag-grid-bottom-controls {
  display:flex;
  column-gap: 10px;
  margin:0.2em;
}
.ag-grid-above-header-container{
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  align-items: flex-start;
  padding: 20px $margin 15px $margin;
  .ag-grid-above-header-container-content{
    margin-left:-10px;
  }
}
@media (max-width:900px) {
  .ag-grid-above-header-container{
    flex-wrap: wrap;
    gap:10px;
  }
}
.ag-grid-above-header-container div:only-child:not(.month-year-input-field):not(.feature-icon){
  margin-left: auto;
}
.ag-grid-above-header-right-side{
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  row-gap: 10px;

}
@media (max-width:610px) {
  .ag-grid-above-header-right-side{
    flex-wrap: wrap;
  }
}

.ag-grid-column-filter-list{
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 5px;

  .filter-chips-close-icon {
    stroke: $secondary-color;
    cursor: pointer;
  }
}
.ag-grid-column-filter-chip{
  background-color: $secondary-color-light;
  border-radius: $border-radius-xl;

  font-size: 12px;
  color:$secondary-color;
  padding:10px 14px;
  font-weight: 600;

  display: flex;
  column-gap: 6px;
  align-items: center;
}
.inner-icon{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left:10px;
}

.ag-grid-quick-filter{
  position: relative;
  stroke:$icon-grey-color;
  height:35px;
  display: flex;
}

.ag-grid-external-filter{
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 13px;
  accent-color: $secondary-color;
  .filter-button-group{
    white-space: nowrap;
  }
}

@mixin status-tag($color, $background-color, $dot-color) {
  background-color: $background-color;
  color:$color;
  border-radius: $border-radius-lg;
  padding: 2px 8px 2px 6px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  font-family: "Space Grotesk", sans-serif;
  &:before {
    font-family: Arial, Helvetica, sans-serif;
    content:" \2022     ";
    color:$dot-color;
  }
}

@mixin badge($color, $background-color){
  line-height:24px;
  background-color: $background-color;
  color:$color;
  white-space:nowrap;
  border-radius: $border-radius-lg;
  padding: 2px 8px 2px 6px;
  position:relative;
  top:-1px;
  text-transform: capitalize;

  font-size: 12px;
  font-weight: 500;
}


.status-tag-positive{
  @include status-tag(#027A48, #ECFDF3,#12B76A);
}

.status-tag-negative{
  @include status-tag(#B42318,#FEF3F2, #ea4337);
}

.status-tag-neutral{
  @include status-tag(#00008F, #EFEFF9, #1010D7);
}
.status-tag-grey{
  @include status-tag(#3d3d3d, #F2F2F2, #4F4F4F);
  border: solid 1px #d5d5d5;
}

.status-tag-grey {
  @include status-tag(#4F4F4F, #F2F2F2, #4F4F4F);
  border: solid 1px #d5d5d5;
}

.status-tag-orange {
  @include status-tag(#DC6803, #FFFAEB, #FDB022);
}
.status-tag-yellow {
  @include status-tag(#957a13, #fafde0, #c8bd00);
}
.status-tag-darkgreen {
  @include status-tag(#033911, #ecffe6, #004703);
  border: solid 1px #a5e4ba;
}
.status-tag-darkred {
  @include status-tag(#780a0a, #ffeaea, #9c0b0b);
  border: solid 1px #c66f6f;
}

.badge-positive {
  @include badge(#027A48, #ECFDF3);
}
.badge-yellow {
  @include badge(#c8bd00, #fdffe7);
}
.badge-negative {
  @include badge(#B42318, #FEF3F2);
}
.badge-neutral {
  @include badge(#00008F, #EFEFF9);
}
.badge-grey {
  @include badge(#4F4F4F, #F2F2F2);
}
.badge-orange {
  @include badge(#DC6803, #FFFAEB);
}