@import "../../sass/module";

.filter-button-group{
  display: flex;
  width:fit-content;
  flex-direction: row;
  align-items: center;
  border:solid 1px $color-grey-light;
  border-radius: $border-radius-xl;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  .filter-button{
    background:white;
    border:none;
    padding: 6px 16px;
    color: $icon-grey-color;
    font-weight: 700;
    font-size:14px;
    margin-right: 0;
    border-right: solid 1px $color-grey-light;
    &:first-child {
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
    }
    &:last-child {
      border-right:none;
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
    }
    &:hover {
      background:$secondary-bg-color;
    }
    &.active {
      background: $secondary-color-lighter2;
    }
  }

}