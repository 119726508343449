@import "../../../sass/module";


.two-factor-inputs {
  display:flex;
  flex-direction:row;
  gap: 20px;

  .two-factor-inputs-input {
    height: 100px;
    width: 100%;
    border-radius: 7px;
    border: 2px solid $color-grey-light;
    text-align: center;
    font-size: 52px;
    line-height: 1;
    padding: 0;

    &.error {
      border: 2px solid $error-color;
      outline:none;
      }
  }
}

// Change styling of input fields when disabled
.two-factor-inputs-input:disabled {
  background-color:darken(white, 5%);
  border: 2px dashed $color-grey-light;
  color: $color-grey-dark;
  cursor: not-allowed;
}