@import "../../sass/module";


.car-details-cost-overview-container{
  position: relative;
  min-height: 100px;
  // the car info tables also have flex grow. When the car details page dont have much content
  // if this one does not grow, it will show at the bottom. Better use the same grow as
  // other tables on car details page
  flex-grow: 1;
}

.car-details-content {
  display: flex;
  flex-wrap: wrap;
  align-content:stretch;
  min-width: fit-content;
  gap:20px;
  flex-grow:1;
}
.page-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}
// this is used for the animation when a mileage is delete, it gradually disappears
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
