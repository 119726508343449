@import "../../sass/module";

.cost-overview-header {
    margin-bottom:0.5em;
}
.cost-overview-content{
    display:flex;
    flex-direction: column;
    gap: 20px;
}
@keyframes resetHighlight {
    0% { background-color: transparent;
    }
    50% { background-color: $secondary-color-lighter; border-radius: $border-radius-xl;} /* Light green color */
    100% { background-color: transparent; }
}

.cost-overview-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}
// button with the class highlight-button-when-load
.highlight-button-when-load {
     button.date-input-field{
        animation: resetHighlight 1s ease-in-out;
    }
}

.cost-overview-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    .cost-info-card-container {
        display:flex;
        flex-direction: column;
        max-width: 350px;
        flex-shrink:1;
        flex-grow:1;
        row-gap:20px;
    }

    .total-cost-diagram-container {
        font-weight: 500;
        min-width: 600px;
        flex-shrink: 2;
        flex-grow:2;
        flex-basis: 600px;

        .chart-container{
            height: 300px;
        }
    }
}
