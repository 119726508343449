@import "../../../sass/module";

.generic-credit-card{
  background: linear-gradient(180deg, #43BFAB 50%, #344054 50%);
  min-width:300px;
  height:180px;
  border-radius: $border-radius-lg;

  padding:20px;
}

.card-content-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: #FFF;
  .card-upper{
    display: flex;
    justify-content: space-between;
    font-family: Inter;
    font-weight: 600;
  }
  .card-lower{
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    // font related
    font-family: Manrope;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: normal;
    letter-spacing: 0.573px;
    text-transform: uppercase;

    .pin-section {
      font-size: $font-size-xs;
      display: flex;
      gap: 5px;
      height: 20px;
      align-items: center;
      margin-bottom:-10px;

      .pin-icon {
        cursor: pointer;
        stroke:white;
      }
    }

    .card-lower-upper{
      display: flex;
      justify-content: space-between;
      font-size: $font-size-xs;
    }
    .card-lower-lower{
      font-size: $font-size-sm;
    }
  }
}