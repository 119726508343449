@import "../../sass/module";

.document-overview-content{
  display:flex;
  flex-direction:column;
  gap: 10px;
  .card-container{
    flex-wrap:wrap;
    display:flex;
    flex-direction: row;
    gap:10px;
  }

  .document-type-selection {
    display:flex;
    flex-direction: column;
    font-weight:500;

    .title{
      position:absolute;
      font-size:$font-size-xs;
      white-space:nowrap;
      color: $icon-grey-color;
      margin-top:-1.5em;
      z-index: 1; //So title is not partially covered by select focus halo effect
    }
  }
}