@import "../../sass/module";

@mixin menu-item-container-padding-medium {
  padding: 5px 15px 5px 15px;
}

@mixin menu-item-container-padding-small {
  padding: 3px 15px 3px 15px;
}


.sidebar-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  min-width: 190px;
  max-width: 250px;
  //background-color: $secondary-bg-color;
  border-right: 2px solid $secondary-bg-color;
  height: 100%;
  .sidebar-component-content {
    @media (min-height: 450px) {
      height: calc(100% - $h1-header-margin-vertical * 2);
    }
    @media (max-height: 450px) {
      height: 100%;
    }

    width: 95%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 20px;
    // reduce row gap for smaller screens
    @media (max-height: map-get($height-breakpoints, s)) {
      row-gap: 10px;
    }


    &>div:nth-child(2) {
      flex-grow: 1;
    }
  }

}

.logo-section {
  display: flex;
  justify-content: center;
  width: 90%;
  cursor: pointer;


  >img {
    width: 70%;
    max-width: 130px;
  }
}

.menu-items-section {
  .menu-items-section-title {
    font-size: 0.8rem;
    color: $secondary-color;
    font-weight: bold;
    @include menu-item-container-padding-medium;
    @media (max-height: 530px) {
      @include menu-item-container-padding-small;
    }
  }

  // this is also a flex box
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 5px;
  @media (max-height:map-get($height-breakpoints, s)) {
    row-gap: 3px;
  }

  .active {
    background: $secondary-color-light;

    &:hover {
      background: $secondary-color-light;
    }
  }
}
.menu-item-container {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
  border-radius: $border-radius-xxl;
  font-weight: $page-name-font-weight;
  color: $page-name-font-color;
  @include menu-item-container-padding-medium;
  @media (max-height: 530px) {
    @include menu-item-container-padding-small;
  }

  transition: 0.5s;

  &:hover {
    background: $secondary-color-lighter;
  }

  .nav-icon {
    stroke: #667085;
    width: 20%;
    max-width: 20px;
    transition: 0.3s;
  }
}


a {
  font: $font-primary;
  color: $text-color;
  box-sizing: border-box;
  text-decoration: none !important;
}

.logout-section {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: color 0.2s ease-in-out;

  .logout-icon {
    width: 20px;
    height: 20px;
    fill: $tab-color;
    flex-shrink: 0;
    z-index: 3;
    &:hover{
      fill:$icon-grey-color;
    }
  }

  span {
    z-index: 2;
  }
}


.useraccount-container {
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-top: 1px solid var(--gray-200, #EAECF0);
  display: flex;
  padding-top:10px;
  justify-content: center;


  .avatar-label-group {
    width:90%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    cursor:pointer;
  }

  .roundavatar {
    display: flex;
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
    min-height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    background: $secondary-color-lighter;
    font-size: 14px;
    font-weight: bold;
    color: $icon-grey-color;
  }

  .user-details {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-width:60%;

  }

  .userName {
    color: var(--gray-700, #344054);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;

  }
}

.userMail {
  color: $page-name-font-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}