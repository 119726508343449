@import "../../../sass/module";
.report-generation-handler{

  .report-generation-modal{
    display:flex;
    flex-direction:column;
    gap: 20px;
    .report-generation-modal-field{
      display:flex;
      flex-direction:column;
      label {
        font-weight: bold;
        color: $color-grey-dark;
      }
      .date-picker-wrapper{
      }
    }
    .report-generation-modal-buttons{
      display:flex;
      flex-direction:row;
      justify-content:space-between;
    }
  }



  .success-message-container {
    display:flex;
    flex-direction:column;
    gap: 10px;
  }
}