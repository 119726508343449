@import "../../../sass/module";

.edit-button {
  float: left;
  width:20px;
  height:20px;
  margin-right:10px;
  color: $color-grey-dark;
  font-size:20px;
  cursor:pointer;
  &:hover {
    color: $gray-600;
  }
}