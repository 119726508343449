@import "../../sass/module";

.car-container{
  background-color: #f8f8f8;
  border-radius: $border-radius-lg;
  padding: 5px;
  margin: 15px 0px;
  th{
    padding: 0px 20px 0px 0px;
  }
}