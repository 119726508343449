@import "../../sass/module";

.home-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .home-section {
    display: flex;
    flex-direction: column;

    .content {

    }

    .fleet-management-content {
      display:flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
    }

    .fleet-structure-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      &[data-single="true"] {
        flex-direction: column;
      }

      .double-cards {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-grow: 1;
        @media (min-width: 992px) {
          flex-basis:250px;
          justify-content: space-between;
        }
        &[data-single="true"] {
          flex-basis: 0;
        }
      }
    }

    .fleet-performance-content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .emission-section {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;

        &[data-chart-count="0"] {
          gap:0;
        }

        .emission-cards {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: space-between;

          @media (min-width: 1080px){
            flex-direction: column;
            min-width: fit-content;
            width: 20%
          }
          // If Chart is empty, place them next to each other
          &[data-chart-count="0"] {
            width: 100%;
            flex-direction: row;
            justify-content: start;
          }
        }

        @media (min-width: 1080px){
          flex-direction: row;
        }

        .performance-charts {
          display: flex;
          flex-direction: row;
          gap: 10px;
          width: 100%;

          .consumption-conversion {
            position: absolute;
            bottom: 5px;
            right: 15px;
            margin: 0;
            color: $tab-color;
            font-size: $font-size-xs;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow:hidden;
            width: 80%;
            max-width: fit-content;
          }
        }
      }

      .performance-metrics {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

    }
  }

}

.usage-list {
  width: 100%;
  height: 100%;
  overflow: auto;

  &[data-two-columns="true"] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    column-gap: 20px;
    row-gap: 8px;

  }

  .usage-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: $border-radius-lg;
    background:white;
    border: solid 1px $gray-background-color;
    box-shadow: $box-shadow-sm;
  }

  .usage-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding: 8px 0;
    width:100%;
    break-inside: avoid;
    border-bottom: solid 1px $grid-separator-color;
    font-size:$font-size-sm;
    .usage-text {
      font-size: 0.85rem;
      display: flex;
      gap: 2px;
      flex-wrap:wrap;

      p {
        margin: 0
      }
      .fuel-type {
        color:$icon-grey-color;
        font-weight: $font-weight-semi-bold;
        margin-right: 4px;
      }
      .value {
        color: $body-color;
      }
      .unit {
        color: $secondary-color;
      }
    }

  }

}
