@import "../../sass/module";

.breadcrumb-wrapper {
    margin-bottom:1em;
    margin-top:1em;
    display: inline-flex;
}

.breadcrumb-container {
    align-items:center;
    display:flex;
    align-content: space-between;
    gap:20px;
    white-space:nowrap;
}

.breadcrumb-separator {
    cursor: default;
    color:$color-grey-light;
    font-size:$font-size-xl;
    margin-left:2px;
}
.breadcrumb-icon {
    margin-right: 0.75em;
    margin-top:3px;

    &.no-margin {
        margin-top:0;
    }
}

.text {
    &:hover {
        color: $brand-color;
    }
    font-weight: $font-weight-semi-bold;
    font-size:$font-size-md;
    cursor:pointer;
    color:$breadcrumb-inactive-color;
    &.with-icon {
        &:hover {
            stroke: $brand-color;
        }
        stroke: $tab-color;
        display:flex;
        width:100%;
    }
    &.active {
        font-weight: $font-weight-bold;
        color: $breadcrumb-active-color2
    }
}