@import "../../sass/module";

.btn-cell{
  @include transition-default(background);
  font-family: $font-primary;
  border-radius: $border-radius-xl;
  height:80%;
  text-align: center;
  border:1px solid $color-grey-light;
  background:$white;
  color: $icon-grey-color;
  font-weight:500;
  font-size: $font-size-sm;
  display: inline-flex;
  align-items: center;
  gap:5px;
  &:hover {
    background:$color-grey-light;
    border: solid $color-grey-light;
  }

  .clock-icon {
    width: 15px;
    height: 15px;
    stroke: $icon-grey-color;
  }
}

.btn {
  @include transition-default(background);
  font-family: $font-primary;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: $border-radius-xxl;
  padding: 10px 20px;
  font-weight:700;
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
  &.min-width-180px{
    min-width: 180px;
  }
  &.sm {
    padding: 10px 16px;
    font-size:$font-size-sm;
    height:40px;
    display:inline-flex;
    border-width: 1px;
    gap:8px;
    justify-content: center;
    align-items: center;
  }
  &.btn-primary {
    background-color: $secondary-color;
    color: $color-white;
    font-size: 14px;
    gap: 10px;
    border: 1px solid $secondary-color;
    &:disabled {
      background: $button-hover-color;
      cursor: default;
      &:hover {
        background: $button-hover-color;
        @media (hover: none) {
          background: $button-hover-color;
        }
      }
    }

    &:hover {
      background: $button-hover-color;
      @media (hover: none) {
        background: $button-hover-color;
      }
    }
  }

  &.btn-secondary-invert {
    @include transition-default(background, color, border);
    background-color: white;
    color: $brand-color;
    font-size: $font-size-md;
    border: solid $brand-color;
    &:hover {
      background: $brand-color;
      border-color: $brand-color;
      color: $color-white;

      @media (hover: none) {
        background: $color-white;
        color: $brand-color;
        border-color: $brand-color;
      }
    }
  }

  &.btn-secondary {
    background-color: $brand-color;
    color: $color-white;
    font-size: $font-size-md;
    gap: 10px;
    border: none;
    &:disabled {
      background: $brand-color;
      cursor: default;
      &:hover {
        background: $brand-color;
        @media (hover: none) {
          background: $brand-color;
        }
      }
    }

    &:hover {
      background: $brand-color-hover;
      @media (hover: none) {
        background: $brand-color-hover;
      }
    }
  }
  &.btn-orange {
    background-color: $orange;
    color: $color-white;
    font-size: $font-size-md;
    gap: 10px;
    border: none;
    &:disabled {
      background: $orange;
      cursor: default;
      &:hover {
        background: $orange;
        @media (hover: none) {
          background: $orange;
        }
      }
    }
    &:hover {
      background: $orange-600;
      @media (hover: none) {
        background: $orange-600;
      }
    }
  }

  &.btn-red {
    background: $error-color;
    border: 1px solid $error-color;
    color: $color-white;
    padding: 12px 40px;
    font-size: $font-size-md;

    &:hover {
      background: $color-white;
      color: $error-color;
      @media (hover: none) {
        background: $error-color;
      }
    }
  }
  &.btn-light {
    &:hover {
      background:$color-grey-light;
      border:1px solid $color-grey-light;
    }
    border:1px solid $color-grey-light;
    background:$white;
    color: $icon-grey-color;
    font-weight:800;
    font-size: $font-size-sm;
  }
  &.btn-dark {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-size: $font-size-sm;

    @include media-breakpoint-up($breakpoint-desktop) {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  &.btn-disabled {
    background-color: $input-border-color;
    border-color: $input-border-color;
    color: transparentize($headline-color, 0.52);
    cursor: not-allowed;

    &:hover {
      background-color: $input-border-color;
      border-color: $input-border-color;
      color: transparentize($headline-color, 0.52);
    }
  }
}

