@import "../../sass/module";

.status-message {
  padding: 10px;
  border: solid 1px;
  border-radius: $border-radius-lg;

  &.with-icon {
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  }

  &.success {
    background-color: $success-message-bg;
    border-color: $success-message-border;
    color: $success-message-text;
    stroke: $success-message-text;
  }

  &.warning {
    background-color: $warning-message-bg;
    border-color: $warning-message-border;
    color: $warning-message-text;
    stroke: $warning-message-text;
  }

  &.error {
    background-color: $error-message-bg;
    border-color: $error-message-border;
    color: $error-message-text;
    stroke: $error-message-text;
  }

  .title {
    font-weight: $font-weight-bold;
  }

  .content {
    font-weight: 400;
  }

  .status-icon{

  }

  .status-message-content {
    margin-top:2px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}