@import "../../sass/module";
.footer-wrapper{
  background-color: $brand-color;
  width: 100%;
  height: $footer-height;
  display: flex;

  justify-content: flex-end;
  align-items: center;


}

.language-list{
  width: 17%;
  max-width: 220px;
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  color: white;


  .language-wrapper{
    width: 25%;
    margin: 0;
    font-size: 15px;
    position: relative;
    // to make short border div centered
    display: flex;
    justify-content: center
  }
  .language-wrapper:hover{
    color:$brand-color-lighter;
  }
  // vertical bars
  //.language-wrapper:nth-child(-n + 3) {
  //  border-right: 2px solid white;
  //}
}
.removeDefaultButtonStyle{
  all: unset;
  display: block;
  // make the button bigger- easier for user to select
  padding-left: 10px;
  padding-right: 10px;
}
.short-border{

  // to make the text centered
  display: flex;
  justify-content: center;

  border-bottom: 2px solid white;
  position: absolute;


  left:12px;
  right:12px;
}


